"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVotingPower = void 0;
const ethers_1 = require("ethers");
const strategyForCommunity_1 = require("../utils/strategyForCommunity");
/**
 * Gets number of votes for an address given a communityAddress:
 */
const getVotingPower = async (userAddress, communityAddress, provider, blockTag) => {
    if (!ethers_1.ethers.utils.isAddress(userAddress))
        throw new Error('User address is not valid');
    if (!ethers_1.ethers.utils.isAddress(communityAddress))
        throw new Error('Community address is not valid');
    // check if community has custom strategy for counting votes
    const strategy = (0, strategyForCommunity_1.strategyForCommunity)(communityAddress);
    if (!strategy)
        throw new Error(`No strategy found for community address ${communityAddress}`);
    return await strategy(userAddress, communityAddress, blockTag, provider);
};
exports.getVotingPower = getVotingPower;
