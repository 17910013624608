"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Comment = exports.Community = exports.DeleteVote = exports.DeleteBIPVote = exports.BIPVote = exports.Vote = exports.SignatureState = exports.Direction = exports.DeleteApplication = exports.DeleteProposal = exports.InfiniteAuctionProposal = exports.UpdatedProposal = exports.Application = exports.Proposal = exports.StoredPendingAuction = exports.StoredInfiniteAuction = exports.InfiniteAuction = exports.StoredTimedBIP = exports.StoredTimedAuction = exports.ApproveRound = exports.TimedDelegate = exports.TimedAuction = exports.TimedBIP = exports.Signable = void 0;
class Signable {
    async typedSignature(signer, domainSeparator, eip712MessageType, primaryType, account) {
        let payload = this.toPayload();
        if (payload.hasOwnProperty('reqAmount'))
            payload.reqAmount = payload.reqAmount.toString();
        return await signer.signTypedData({
            account,
            domain: domainSeparator,
            types: eip712MessageType,
            message: payload,
            primaryType: primaryType,
        });
    }
    /** sign typed data */
    async signedPayload(signer) {
        const jsonPayload = this.jsonPayload();
        const address = (await signer.getAddresses())[0];
        let payload = {
            address: address,
            ...this.toPayload(),
        };
        const signMessage = JSON.stringify(payload);
        const signature = await signer.signMessage({
            account: address,
            message: signMessage,
        });
        if (!signature)
            throw new Error(`Error signing payload.`);
        return {
            signedData: {
                message: Buffer.from(signMessage).toString('base64'),
                signature: signature,
                signer: address,
            },
            owner: address,
            ...payload,
        };
    }
    jsonPayload() {
        return JSON.stringify(this.toPayload());
    }
}
exports.Signable = Signable;
class TimedBIP extends Signable {
    constructor(title, optionType, options, startTime, endTime, content, previewImage) {
        super();
        this.title = title;
        this.optionType = optionType;
        this.options = options;
        this.startTime = startTime;
        this.endTime = endTime;
        this.content = content;
        this.previewImage = previewImage;
    }
    toPayload() {
        return {
            title: this.title,
            optionType: this.optionType,
            options: this.options,
            startTime: this.startTime.toISOString(),
            endTime: this.endTime.toISOString(),
            content: this.content,
            previewImage: this.previewImage,
        };
    }
}
exports.TimedBIP = TimedBIP;
class TimedAuction extends Signable {
    constructor(visible, title, startTime, proposalEndTime, votingEndTime, fundingAmount, currencyType, numWinners, community, communityId, balanceBlockTag, description) {
        super();
        this.visible = visible;
        this.title = title;
        this.startTime = startTime;
        this.proposalEndTime = proposalEndTime;
        this.votingEndTime = votingEndTime;
        this.fundingAmount = fundingAmount;
        this.currencyType = currencyType;
        this.numWinners = numWinners;
        this.community = community;
        this.communityId = communityId;
        this.balanceBlockTag = balanceBlockTag;
        this.description = description;
    }
    toPayload() {
        return {
            visible: this.visible,
            title: this.title,
            startTime: this.startTime.toISOString(),
            proposalEndTime: this.proposalEndTime.toISOString(),
            votingEndTime: this.votingEndTime.toISOString(),
            fundingAmount: this.fundingAmount,
            currencyType: this.currencyType,
            numWinners: this.numWinners,
            community: this.community,
            communityId: this.communityId,
            balanceBlockTag: this.balanceBlockTag,
            description: this.description,
        };
    }
}
exports.TimedAuction = TimedAuction;
class TimedDelegate extends Signable {
    constructor(title, startTime, endTime, proposalEndTime, votingEndTime, description) {
        super();
        this.title = title;
        this.startTime = startTime;
        this.endTime = endTime;
        this.proposalEndTime = proposalEndTime;
        this.votingEndTime = votingEndTime;
        this.description = description;
    }
    toPayload() {
        return {
            title: this.title,
            startTime: this.startTime.toISOString(),
            endTime: this.endTime.toISOString(),
            proposalEndTime: this.proposalEndTime.toISOString(),
            votingEndTime: this.votingEndTime.toISOString(),
            description: this.description,
        };
    }
}
exports.TimedDelegate = TimedDelegate;
class ApproveRound extends Signable {
    constructor(id, visibleStatus) {
        super();
        this.id = id;
        this.visibleStatus = visibleStatus;
    }
    toPayload() {
        return {
            id: this.id,
            visibleStatus: this.visibleStatus,
        };
    }
}
exports.ApproveRound = ApproveRound;
class StoredTimedAuction extends TimedAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
            proposalEndTime: new Date(response.proposalEndTime),
            votingEndTime: new Date(response.votingEndTime),
        };
        return parsed;
    }
}
exports.StoredTimedAuction = StoredTimedAuction;
class StoredTimedBIP extends TimedAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
            endTime: new Date(response.endTime),
        };
        return parsed;
    }
}
exports.StoredTimedBIP = StoredTimedBIP;
class InfiniteAuction extends Signable {
    constructor(visible, title, startTime, fundingAmount, currencyType, communityId, balanceBlockTag, description, quorum, votingPeriod) {
        super();
        this.visible = visible;
        this.title = title;
        this.startTime = startTime;
        this.fundingAmount = fundingAmount;
        this.currencyType = currencyType;
        this.communityId = communityId;
        this.balanceBlockTag = balanceBlockTag;
        this.description = description;
        this.quorum = quorum;
        this.votingPeriod = votingPeriod;
    }
    toPayload() {
        return {
            visible: this.visible,
            title: this.title,
            startTime: this.startTime.toISOString(),
            fundingAmount: this.fundingAmount,
            currencyType: this.currencyType,
            communityId: this.communityId,
            balanceBlockTag: this.balanceBlockTag,
            description: this.description,
            quorum: this.quorum,
            votingPeriod: this.votingPeriod,
        };
    }
}
exports.InfiniteAuction = InfiniteAuction;
class StoredInfiniteAuction extends InfiniteAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
        };
        return parsed;
    }
}
exports.StoredInfiniteAuction = StoredInfiniteAuction;
class StoredPendingAuction extends InfiniteAuction {
    static FromResponse(response) {
        const parsed = {
            ...response,
            startTime: new Date(response.startTime),
        };
        return parsed;
    }
}
exports.StoredPendingAuction = StoredPendingAuction;
class Proposal extends Signable {
    constructor(title, what, tldr, auctionId, parentType = 'auction', previewImage = '') {
        super();
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.parentType = parentType;
        this.previewImage = previewImage;
    }
    toPayload() {
        return {
            title: this.title,
            what: this.what,
            tldr: this.tldr,
            parentAuctionId: this.auctionId,
            parentType: this.parentType,
            previewImage: this.previewImage,
        };
    }
}
exports.Proposal = Proposal;
class Application extends Signable {
    constructor(title, description, tldr, delegationId, parentType = 'auction', previewImage = '') {
        super();
        this.title = title;
        this.description = description;
        this.tldr = tldr;
        this.delegationId = delegationId;
        this.parentType = parentType;
        this.previewImage = previewImage;
    }
    toPayload() {
        return {
            title: this.title,
            description: this.description,
            tldr: this.tldr,
            delegationId: this.delegationId,
            parentType: this.parentType,
            previewImage: this.previewImage,
        };
    }
}
exports.Application = Application;
class UpdatedProposal extends Proposal {
    constructor(id, title, what, tldr, auctionId, previewImage = '', parentType = 'auction') {
        super(title, what, tldr, auctionId, parentType);
        this.id = id;
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.previewImage = previewImage;
        this.parentType = parentType;
    }
    toPayload() {
        return {
            id: this.id,
            ...super.toPayload(),
        };
    }
}
exports.UpdatedProposal = UpdatedProposal;
class InfiniteAuctionProposal extends Signable {
    constructor(title, what, tldr, auctionId, reqAmount, parentType = 'infinite-auction') {
        super();
        this.title = title;
        this.what = what;
        this.tldr = tldr;
        this.auctionId = auctionId;
        this.reqAmount = reqAmount;
        this.parentType = parentType;
    }
    toPayload() {
        return {
            title: this.title,
            what: this.what,
            tldr: this.tldr,
            parentAuctionId: this.auctionId,
            parentType: this.parentType,
            reqAmount: this.reqAmount,
        };
    }
}
exports.InfiniteAuctionProposal = InfiniteAuctionProposal;
class DeleteProposal extends Signable {
    constructor(id) {
        super();
        this.id = id;
    }
    toPayload() {
        return {
            id: this.id,
        };
    }
}
exports.DeleteProposal = DeleteProposal;
class DeleteApplication extends Signable {
    constructor(id) {
        super();
        this.id = id;
    }
    toPayload() {
        return {
            applicationId: this.id,
        };
    }
}
exports.DeleteApplication = DeleteApplication;
var Direction;
(function (Direction) {
    Direction[Direction["Up"] = 1] = "Up";
    Direction[Direction["Down"] = -1] = "Down";
    Direction[Direction["Abstain"] = 0] = "Abstain";
})(Direction || (exports.Direction = Direction = {}));
var SignatureState;
(function (SignatureState) {
    SignatureState["PENDING_VALIDATION"] = "PENDING_VALIDATION";
    SignatureState["FAILED_VALIDATION"] = "FAILED_VALIDATION";
    SignatureState["VALIDATED"] = "VALIDATED";
})(SignatureState || (exports.SignatureState = SignatureState = {}));
class Vote extends Signable {
    constructor(
    // public readonly direction: Direction,
    proposalId, weight) {
        super();
        this.proposalId = proposalId;
        this.weight = weight;
    }
    toPayload() {
        return {
            // No need direction
            // direction: this.direction,
            proposalId: this.proposalId,
            weight: this.weight,
        };
    }
}
exports.Vote = Vote;
class BIPVote extends Signable {
    constructor(
    // public readonly direction: Direction,
    bipRoundId, bipOptionId) {
        super();
        this.bipRoundId = bipRoundId;
        this.bipOptionId = bipOptionId;
    }
    toPayload() {
        return {
            // No need direction
            // direction: this.direction,
            bipRoundId: this.bipRoundId,
            bipOptionId: this.bipOptionId,
        };
    }
}
exports.BIPVote = BIPVote;
class DeleteBIPVote extends Signable {
    constructor(bipRoundId) {
        super();
        this.bipRoundId = bipRoundId;
    }
    toPayload() {
        return {
            // No need direction
            // direction: this.direction,
            bipRoundId: this.bipRoundId,
        };
    }
}
exports.DeleteBIPVote = DeleteBIPVote;
class DeleteVote extends Signable {
    constructor(proposalId) {
        super();
        this.proposalId = proposalId;
    }
    toPayload() {
        return {
            // No need direction
            // direction: this.direction,
            proposalId: this.proposalId,
        };
    }
}
exports.DeleteVote = DeleteVote;
class Community extends Signable {
    constructor(id, contractAddress, name, profileImageUrl, numAuctions, numProposals, ethFunded, totalFunded, description) {
        super();
        this.id = id;
        this.contractAddress = contractAddress;
        this.name = name;
        this.profileImageUrl = profileImageUrl;
        this.numAuctions = numAuctions;
        this.numProposals = numProposals;
        this.ethFunded = ethFunded;
        this.totalFunded = totalFunded;
        this.description = description;
    }
    toPayload() {
        return {
            id: this.id,
            contractAddress: this.contractAddress,
            name: this.name,
            profileImageUrl: this.profileImageUrl,
            numAuctions: this.numAuctions,
            numProposals: this.numProposals,
            ethFunded: this.ethFunded,
            totalFunded: this.totalFunded,
            description: this.description,
        };
    }
}
exports.Community = Community;
class Comment extends Signable {
    constructor(content, proposalId, applicationId, bipRoundId) {
        super();
        this.content = content;
        this.proposalId = proposalId;
        this.applicationId = applicationId;
        this.bipRoundId = bipRoundId;
    }
    toPayload() {
        return {
            content: this.content,
            proposalId: this.proposalId,
            applicationId: this.applicationId,
            bipRoundId: this.bipRoundId,
        };
    }
}
exports.Comment = Comment;
