"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockRandomByBlock = void 0;
// Just for test: return a 1-10 random voting power by blocknum & useraddress.
// Won't change when block tag and user address not change.
const mockRandomByBlock = () => {
    return async (userAddress, communityAddress, blockTag, provider) => {
        return (blockTag % 10 + Number(BigInt(userAddress) % BigInt(10))) % 10;
    };
};
exports.mockRandomByBlock = mockRandomByBlock;
