"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.communities = void 0;
const strategies_1 = require("../strategies");
const CaseInsensitiveMap_1 = require("../types/CaseInsensitiveMap");
const snapshotMultiple_1 = require("../strategies/snapshotMultiple");
const mockRandomByBlock_1 = require("../strategies/mockRandomByBlock");
/**
 * Contract addresses for communities that require custom voting strategy.
 */
exports.communities = new CaseInsensitiveMap_1.CaseInsensitiveMap(Object.entries({
    // These strategies from : https://snapshot.org/#/council.bibliotheca.eth/proposal/0x755fc15017bc4a061e385c3fe4cd65b9e39496ba028577b4828c3a3f8dc4b71f
    '0x7AFe30cB3E53dba6801aa0EA647A0EcEA7cBe18d': (0, strategies_1.snapshotMultiple)([
        {
            address: '0x17963290db8c30552d0cfa2a6453ff20a28c31a2',
            strategyType: snapshotMultiple_1.StrategyType.ContractCall,
            multiplier: 1,
        },
        {
            address: '0xcdfe3d7ebfa793675426f150e928cd395469ca53',
            strategyType: snapshotMultiple_1.StrategyType.ContractCall,
            multiplier: 1,
        },
        {
            address: '0x7AFe30cB3E53dba6801aa0EA647A0EcEA7cBe18d',
            strategyType: snapshotMultiple_1.StrategyType.Erc721,
            multiplier: 1,
        },
    ]),
    // Mock strategies.
    '0x82cE4e52918B83cCf7072594db05Eb186443A62F': (0, mockRandomByBlock_1.mockRandomByBlock)(),
    // Test strategies on test chain
    '0x86f7692569914b5060ef39aab99e62ec96a6ed45': (0, strategies_1.snapshotMultiple)([
        {
            address: '0x86f7692569914b5060ef39aab99e62ec96a6ed45',
            strategyType: snapshotMultiple_1.StrategyType.Erc721,
            multiplier: 1,
        },
    ]),
    // Chao-minted test NFT on test chain : https://goerli.etherscan.io/address/0x830234828de71f7aa8a5050a8bbe58db9c2992f5
    '0x830234828de71f7aa8a5050a8bbe58db9c2992f5': (0, strategies_1.snapshotMultiple)([
        {
            address: '0x830234828de71f7aa8a5050a8bbe58db9c2992f5',
            strategyType: snapshotMultiple_1.StrategyType.Erc721,
            multiplier: 1,
        },
    ]),
}));
