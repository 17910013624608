export const NounImage = {
  Banana: { src: '/heads/banana.png', alt: 'banana' },
  Blackhole: { src: '/heads/blackhole.png', alt: 'blackhole' },
  Calculator: { src: '/heads/calculator.png', alt: 'calculator' },
  Camera: { src: '/heads/camera.png', alt: 'camera' },
  Chart: { src: '/heads/chart.png', alt: 'chart' },
  Computer: { src: '/heads/computer.png', alt: 'computer' },
  Cone: { src: '/heads/cone.png', alt: 'cone' },
  Crown: { src: '/heads/crown.png', alt: 'crown' },
  Glasses: { src: 'https://cdn.metaforo.io/upload/20230706/d08103b6fcf9bb97b4db83e4ee43f221.jpg', alt: 'glasses' },
  Heart: { src: '/heads/heart.png', alt: 'heart' },
  Laptop: { src: '/heads/laptop.png', alt: 'laptop' },
  Thumbsup: { src: '/heads/thumbsup.png', alt: 'thumbsup' },
  Trashcan: { src: '/heads/trashcan.png', alt: 'trashcan' },
  Wallet: { src: '/heads/wallet.png', alt: 'wallet' },
  Whale: { src: '/heads/whale.png', alt: 'whale' },
};
