"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuctionVisibleStatus = void 0;
var AuctionVisibleStatus;
(function (AuctionVisibleStatus) {
    AuctionVisibleStatus[AuctionVisibleStatus["PENDING"] = 0] = "PENDING";
    AuctionVisibleStatus[AuctionVisibleStatus["NORMAL"] = 1] = "NORMAL";
    // The backend will not use this status temporarily, and setting it to this status will delete it directly.
    AuctionVisibleStatus[AuctionVisibleStatus["REJECT"] = 2] = "REJECT";
})(AuctionVisibleStatus || (exports.AuctionVisibleStatus = AuctionVisibleStatus = {}));
