import classes from './Footer.module.css';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import bgColorForFooter from '../../utils/bgColorForFooter';
import { FaDiscord, FaTwitter, FaGithub } from 'react-icons/fa';
import { externalURL, ExternalURL } from '../../utils/externalURLs';

const Footer = () => {
  const location = useLocation();

  const twitterURL = externalURL(ExternalURL.twitter);
  const discordURL = externalURL(ExternalURL.discord);
  const githubURL = externalURL(ExternalURL.github);
  const usermannualUrl = externalURL(ExternalURL.usermannual);

  return (
    <div className={clsx(classes.footerContainer, bgColorForFooter(location.pathname))}>
      <div className={classes.footer}>
        <a href={twitterURL} target="_blank" rel="noreferrer">
          <svg width="20" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 19.1828C2.32527 19.2245 2.65285 19.2456 2.98077 19.246C4.84467 19.2447 6.64859 18.587 8.07597 17.3882C6.27974 17.3455 4.76586 16.0747 4.24389 14.3321C4.49729 14.3864 4.75562 14.4144 5.01477 14.4157C5.38027 14.4184 5.74429 14.3689 6.09585 14.2689C4.21789 13.8546 2.80245 12.0729 2.80245 9.94945V9.89372C3.36514 10.2323 4.00389 10.4239 4.65998 10.4511C4.08874 10.027 3.62576 9.47392 3.30877 8.83694C2.99178 8.19995 2.82975 7.49708 2.83589 6.78558C2.83322 6.01827 3.02493 5.26279 3.39315 4.58963C5.41785 7.26676 8.44376 9.04841 11.8523 9.26392C11.7813 8.93866 11.7458 8.60663 11.7464 8.2737C11.7464 5.87339 13.5835 3.95797 15.8497 4.0007C16.4202 4.0141 16.9815 4.14682 17.4975 4.39032C18.0135 4.63382 18.4729 4.9827 18.8459 5.41451C19.7656 5.23674 20.6462 4.89638 21.4465 4.40942C21.152 5.38857 20.5108 6.22677 19.6428 6.767C20.4574 6.67674 21.2528 6.45921 22 6.12234C21.4558 6.9723 20.7638 7.71791 19.9567 8.32386C19.9641 8.50964 19.9679 8.69542 19.9679 8.88121C19.9679 14.5792 15.8404 21.1521 8.29145 21.1428C6.04366 21.1438 3.84899 20.4612 2 19.1828Z" fill="white"/>
          </svg>

        </a>

        <a href={discordURL} target="_blank" rel="noreferrer">
          <svg width="20" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.03228 11.3817C9.23077 11.3817 10.2013 12.3 10.1811 13.4223C10.1811 14.5456 9.23279 15.4628 8.03228 15.4628C6.85397 15.4628 5.88348 14.5456 5.88348 13.4223C5.88348 12.3 6.83178 11.3817 8.03228 11.3817ZM15.7195 11.3817C16.9201 11.3817 17.8683 12.3 17.8683 13.4223C17.8683 14.5456 16.9201 15.4628 15.7195 15.4628C14.5412 15.4628 13.5718 14.5456 13.5718 13.4223C13.5718 12.3 14.519 11.3817 15.7195 11.3817ZM17.711 21C22.2517 20.8506 24 17.7434 24 17.7434C24 10.8428 21.0391 5.24989 21.0391 5.24989C18.0832 2.9373 15.2666 3.00017 15.2666 3.00017L14.9791 3.34334C18.4726 4.45738 20.0938 6.0661 20.0938 6.0661C18.1864 4.96826 16.0842 4.26902 13.9097 4.00909C12.5301 3.85004 11.1367 3.86389 9.7604 4.05031C9.63632 4.05031 9.53342 4.07092 9.41135 4.09153C8.69206 4.15749 6.94376 4.43471 4.74653 5.44363C3.98689 5.80639 3.53392 6.0661 3.53392 6.0661C3.53392 6.0661 5.23985 4.37082 8.9372 3.25781L8.7314 3.00017C8.7314 3.00017 5.91677 2.9373 2.95889 5.25092C2.95889 5.25092 2.36444e-08 10.8428 0 17.7424C0 17.7424 1.7251 20.8485 6.26684 20.999C6.26684 20.999 7.02547 20.0364 7.64489 19.2223C5.03405 18.4061 4.04943 16.6912 4.04943 16.6912C4.04943 16.6912 4.25322 16.8427 4.62245 17.0571C4.64262 17.0777 4.6628 17.1003 4.70416 17.1199C4.76671 17.1653 4.82825 17.1859 4.89079 17.2281C5.40328 17.528 5.91677 17.764 6.3889 17.9567C7.23127 18.2999 8.23808 18.6431 9.41135 18.8791C11.1679 19.2309 12.9739 19.2379 14.7329 18.8997C15.7574 18.7129 16.7569 18.404 17.711 17.9794C18.5432 17.6503 19.3362 17.2257 20.0747 16.7139C20.0747 16.7139 19.0477 18.471 16.3551 19.2656C16.8007 19.8495 17.2533 20.4276 17.713 21H17.711Z" fill="white"/>
          </svg>

        </a>

        <a href={githubURL} target="_blank" rel="noreferrer">
          <svg width="20" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1.00059C9.11346 0.969888 6.33338 2.14001 4.27073 4.2538C2.20809 6.36759 1.03166 9.25208 1 12.2733C1.01112 14.661 1.74494 16.9835 3.09606 18.9074C4.44718 20.8312 6.34607 22.2573 8.52 22.9809C9.07 23.0856 9.27 22.7401 9.27 22.4366V20.5212C6.21 21.2015 5.56 19.014 5.56 19.014C5.3563 18.3272 4.92323 17.7402 4.34 17.3602C3.34 16.6694 4.42 16.6799 4.42 16.6799C4.76565 16.7282 5.09635 16.8579 5.38731 17.0591C5.67826 17.2603 5.92192 17.5279 6.1 17.8417C6.41033 18.4049 6.91953 18.8181 7.51801 18.9923C8.11649 19.1666 8.75639 19.0879 9.3 18.7732C9.35591 18.2 9.60389 17.6661 10 17.266C7.56 16.9834 5 16.0205 5 11.6977C4.97818 10.5701 5.37624 9.47777 6.11 8.65183C5.77583 7.68372 5.81529 6.61741 6.22 5.67925C6.22 5.67925 7.15 5.37571 9.22 6.8306C11.0219 6.32803 12.9181 6.32803 14.72 6.8306C16.82 5.37571 17.72 5.67925 17.72 5.67925C18.1247 6.61741 18.1642 7.68372 17.83 8.65183C18.5808 9.46263 19.0005 10.5476 19 11.6767C19 16.01 16.42 16.9625 14 17.2451C14.265 17.5144 14.4697 17.8417 14.5994 18.2037C14.729 18.5657 14.7805 18.9532 14.75 19.3384V22.4261C14.75 22.7925 14.95 23.0856 15.5 22.9704C17.6678 22.2424 19.5603 20.8159 20.9072 18.8948C22.2541 16.9737 22.9866 14.6562 23 12.2733C22.9683 9.25208 21.7919 6.36759 19.7293 4.2538C17.6666 2.14001 14.8865 0.969888 12 1.00059Z" fill="white"/>
          </svg>
        </a>
        <a href={usermannualUrl} target="_blank" rel="noreferrer">
          <svg width="20" height="21" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 4.0213C21 2.90735 20.0021 2 18.7771 2H5.36042C4.05833 2 3 2.97764 3 4.18104V18.8115C3 18.869 3.00625 18.9244 3.01667 18.9776C3.00625 19.0756 3 19.1736 3 19.2737C3 20.7774 4.19583 22 5.66667 22H18.7771C20.0021 22 21 20.9798 21 19.7274V4.0213ZM5.36042 3.81683H18.7771C19.0729 3.81683 19.2208 3.98296 19.2208 4.0213V16.5474H17.5458C17.5167 16.5453 17.4896 16.5389 17.4604 16.5389H10.1271C10.0979 16.5389 10.0687 16.5453 10.0417 16.5474H5.66667C5.35417 16.5474 5.05625 16.6049 4.77708 16.7071V4.17891C4.77708 4.03195 5.00417 3.81683 5.36042 3.81683ZM19.2229 19.7295C19.2229 19.9808 19.0229 20.1832 18.7792 20.1832H5.66667C5.17708 20.1832 4.77708 19.7764 4.77708 19.2737C4.77708 18.771 5.175 18.3642 5.66667 18.3642H19.2229V19.7295Z" fill="white"/>
            <path d="M7.44375 7.44835H15.7771C16.2688 7.44835 16.6667 7.04153 16.6667 6.53887C16.6667 6.03621 16.2688 5.62939 15.7771 5.62939H7.44375C6.95208 5.62939 6.55417 6.03621 6.55417 6.53887C6.55417 7.04153 6.95417 7.44835 7.44375 7.44835ZM7.44375 10.1747H14C14.4917 10.1747 14.8896 9.76784 14.8896 9.26517C14.8896 8.76251 14.4917 8.3557 14 8.3557H7.44375C6.95208 8.3557 6.55417 8.76251 6.55417 9.26517C6.55417 9.76784 6.95417 10.1747 7.44375 10.1747Z" fill="white"/>
          </svg>
        </a>
      </div>
    </div>
  );
};

export default Footer;
